import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Box, FormControl, InputLabel, MenuItem, Stack, Typography } from '@trustyou/ui';

import { QuestionEditorBinaryType } from './components/binary-question-editor';
import { QuestionEditorNumberType } from './components/number-question-editor';
import { QuestionEditorScaleRatingType } from './components/scale-question-editor';
import { QuestionEditorSelectType } from './components/select-question-editor';
import { QuestionEditorTextType } from './components/text-question-editor';

import {
  LANGUAGE_OPTIONS,
  MAX_QUESTION_EDITOR_FIELD_WIDTH_PX,
  QUESTION_TYPES_BY_CATEGORY,
} from '../../../feedback/constants';
import { CustomTextFieldWithSeparatedLabel } from '../../../shared';
import { QUESTION_LIBRARY_EDITOR_TYPE_OPTIONS } from '../../constants/questions';

export function QuestionLibraryEditorSettingsContent() {
  const initialChosenQuestionTypeCompleted = useRef(false);
  const { state: chosenQuestionType } = useLocation();
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    resetField,
  } = useFormContext();

  const watchedQuestionType = watch('questionType', chosenQuestionType.value);
  const watchedQuestionTypeVariation = watch('questionTypeVariation', chosenQuestionType.variation);
  const isQuestionTypeBinaryCheckbox =
    watchedQuestionType === 'binary' && watchedQuestionTypeVariation === 'checkbox';

  const selectedQuestionTypeItem = QUESTION_LIBRARY_EDITOR_TYPE_OPTIONS.find(
    (option) => option.value === watchedQuestionType
  );

  const selectedQuestionTypeVariationItem = selectedQuestionTypeItem?.subOptions?.find(
    (option) => option.value === watchedQuestionTypeVariation
  );

  useEffect(() => {
    setValue('questionType', watchedQuestionType);
    setValue('questionTypeVariation', watchedQuestionTypeVariation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialChosenQuestionTypeCompleted.current) {
      if ((selectedQuestionTypeItem?.subOptions?.length ?? 0) > 0) {
        const defaultVariation = selectedQuestionTypeItem?.subOptions?.[0].value;
        setValue('questionTypeVariation', defaultVariation);
      }
    } else {
      initialChosenQuestionTypeCompleted.current = true;
    }
  }, [selectedQuestionTypeItem, setValue]);

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        <FormattedMessage
          id="survey.question-editor.step-settings.title"
          defaultMessage="New custom question"
        />
      </Typography>
      <Stack spacing={6}>
        <Stack spacing={3}>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink>
              <FormattedMessage
                id="survey.question-editor.internal-name.label"
                defaultMessage="Internal name (not visible to guests)"
              />
              <Box component="span" sx={{ marginLeft: 0.5 }}>
                *
              </Box>
            </InputLabel>
            <CustomTextFieldWithSeparatedLabel
              fullWidth
              size="small"
              multiline
              maxRows={3}
              error={!!errors?.internalName}
              helperText={
                !!errors?.internalName && (
                  <FormattedMessage
                    id="survey.common.mandatory-field"
                    defaultMessage="Mandatory field"
                  />
                )
              }
              {...register('internalName', { required: true })}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink>
              <FormattedMessage
                id="survey.question-editor.question-text.label"
                defaultMessage="Question text (visible to guests)"
              />
              {!isQuestionTypeBinaryCheckbox && (
                <Box component="span" sx={{ marginLeft: 0.5 }}>
                  *
                </Box>
              )}
            </InputLabel>
            <CustomTextFieldWithSeparatedLabel
              fullWidth
              size="small"
              multiline
              maxRows={3}
              disabled={isQuestionTypeBinaryCheckbox}
              sx={{ backgroundColor: isQuestionTypeBinaryCheckbox ? '#F5F5F5' : null }}
              error={!!errors?.questionText}
              helperText={
                !!errors?.questionText && (
                  <FormattedMessage
                    id="survey.common.mandatory-field"
                    defaultMessage="Mandatory field"
                  />
                )
              }
              {...register('questionText', {
                required: !isQuestionTypeBinaryCheckbox,
              })}
            />
          </FormControl>
          <FormControl variant="standard" fullWidth>
            <InputLabel shrink>
              <FormattedMessage
                id="survey.question-editor.additional-text.label"
                defaultMessage="Additional text (visible to guests)"
              />
            </InputLabel>
            <CustomTextFieldWithSeparatedLabel
              fullWidth
              size="small"
              multiline
              maxRows={3}
              disabled={isQuestionTypeBinaryCheckbox}
              sx={{ backgroundColor: isQuestionTypeBinaryCheckbox ? '#F5F5F5' : null }}
              error={!!errors?.additionalText}
              helperText={
                <FormattedMessage
                  id="survey.survey-editor.additional-text.helper-text"
                  defaultMessage="Optional. Leave it empty if none is needed"
                />
              }
              {...register('additionalText', { required: false })}
            />
          </FormControl>
          <FormControl variant="standard" sx={{ maxWidth: MAX_QUESTION_EDITOR_FIELD_WIDTH_PX }}>
            <InputLabel shrink>
              <FormattedMessage
                id="survey.editor.default-language.label"
                defaultMessage="Default Language"
              />
            </InputLabel>
            <CustomTextFieldWithSeparatedLabel
              fullWidth
              select
              size="small"
              value={watch('defaultLanguage') ?? 'en'}
              {...register('defaultLanguage', { value: 'en' })}
            >
              {LANGUAGE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomTextFieldWithSeparatedLabel>
          </FormControl>
        </Stack>

        <Stack spacing={3}>
          <FormControl variant="standard" sx={{ maxWidth: MAX_QUESTION_EDITOR_FIELD_WIDTH_PX }}>
            <InputLabel shrink>
              <FormattedMessage id="survey.common.type" defaultMessage="Type" />
            </InputLabel>
            <CustomTextFieldWithSeparatedLabel
              fullWidth
              select
              size="small"
              helperText={selectedQuestionTypeItem?.helperText}
              value={watchedQuestionType}
              {...register('questionType', { required: true })}
              onChange={(e) => {
                setValue('questionType', e.target.value);
                resetField('savedTranslations');
              }}
            >
              {QUESTION_LIBRARY_EDITOR_TYPE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CustomTextFieldWithSeparatedLabel>
          </FormControl>
          {!QUESTION_TYPES_BY_CATEGORY.withoutVariation.includes(watch('questionType')) && (
            <FormControl variant="standard" sx={{ maxWidth: MAX_QUESTION_EDITOR_FIELD_WIDTH_PX }}>
              <InputLabel shrink>
                <FormattedMessage id="survey.common.variation" defaultMessage="Variation" />
              </InputLabel>
              <CustomTextFieldWithSeparatedLabel
                fullWidth
                select
                size="small"
                value={watchedQuestionTypeVariation}
                helperText={selectedQuestionTypeVariationItem?.helperText}
                {...register('questionTypeVariation', { required: true })}
                onChange={(e) => {
                  setValue('questionTypeVariation', e.target.value);
                  resetField('savedTranslations');
                }}
              >
                {selectedQuestionTypeItem?.subOptions?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </CustomTextFieldWithSeparatedLabel>
            </FormControl>
          )}
          {QUESTION_TYPES_BY_CATEGORY.select.includes(watch('questionType') as string) && (
            <QuestionEditorSelectType />
          )}
          {QUESTION_TYPES_BY_CATEGORY.scale.includes(watch('questionType') as string) && (
            <QuestionEditorScaleRatingType />
          )}
          {watch('questionType') === 'text' && <QuestionEditorTextType />}
          {watch('questionType') === 'number' && <QuestionEditorNumberType />}
          {watch('questionType') === 'binary' && <QuestionEditorBinaryType />}
        </Stack>
      </Stack>
    </>
  );
}
