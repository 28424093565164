import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { EmptyStatePlaceholder } from '@trustyou/ui';

// eslint-disable-next-line @nx/enforce-module-boundaries
import personDoingDesignProcess from '../../../../../../ui/src/lib/assets/illustrations/person-doing-design-process.svg';
import { useStore } from '../../store/store';

export function ThemeEmptyState() {
  const navigate = useNavigate();
  const setIsEditorOpen = useStore.use.setIsThemeEditorOpen();

  const navigateToThemeEditor = () => {
    setIsEditorOpen(true);
    navigate('theme/editor');
  };

  return (
    <EmptyStatePlaceholder
      img={personDoingDesignProcess}
      title={
        <FormattedMessage id="survey.theme.empty-state.title" defaultMessage="No themes yet" />
      }
      description={
        <FormattedMessage
          id="survey.empty-state.description"
          defaultMessage="Click below to create your first one"
        />
      }
      emptyStateHeader={{ marginTop: 3 }}
      action={{
        testId: 'survey-theme-empty-state',
        startIcon: <FontAwesomeIcon icon={faPlus} />,
        message: <FormattedMessage id="survey.new-theme.button" defaultMessage="New Theme" />,
        handler: navigateToThemeEditor,
        styles: { marginTop: 3 },
      }}
    />
  );
}
