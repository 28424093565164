import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useQueryClient } from '@tanstack/react-query';
import {
  faArrowLeft,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faLanguage,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useLanguageStore, useResponsive } from '@trustyou/shared';
import { Box, Button, Divider, Stack, StyledFontAwesomeIcon, snackbar } from '@trustyou/ui';

import { MARKED_AS_RESPONDED_WITH_AN_EMPTY_TEXT } from '../../constants';
import {
  updateReviewStatusById,
  useLeaveDialog,
  useSaveResponse,
  useSetReviewReadStatus,
  useTranslatePermission,
} from '../../hooks';
import useSetReviewsRespondedStatusPermission from '../../hooks/permissions/use-set-reviews-responded-status-permission';
import useSetReviewsStatusPermission from '../../hooks/permissions/use-set-reviews-status-permission';
import { useStore } from '../../store/store';
import type { ReviewRoot } from '../../types';
import {
  isDeletionConfirmed,
  isDeletionPendingOrRequested,
  useSurroundingReviewIds,
} from '../../utils/review';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip';
import { ReviewDeletion } from '../review-deletion';
import { SurveyModeration } from '../survey-moderation/survey-moderation';

type ReviewDetailToolbarProps = {
  review: ReviewRoot;
  isLoading: boolean;
  shouldFetchTranslation: boolean;
  setShouldFetchTranslation: (value: boolean) => void;
  shouldDisplayTranslation: boolean;
  setShouldDisplayTranslation: (value: boolean) => void;
};

export function ReviewDetailToolbar({
  review,
  isLoading,
  shouldFetchTranslation,
  setShouldFetchTranslation,
  shouldDisplayTranslation,
  setShouldDisplayTranslation,
}: ReviewDetailToolbarProps) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const { reviewId = '' } = useParams();
  const { prevReviewId, nextReviewId } = useSurroundingReviewIds(reviewId);
  const { accessTokenPayload } = useOidcAccessToken();
  const saveResponse = useSaveResponse();
  const { isMobile } = useResponsive();

  const isResponseFormDirty = useStore.use.isResponseFormDirty();
  const updateHasExistingResponse = useStore.use.updateHasExistingResponse();
  const { locale } = useLanguageStore();

  const setReviewStatus = useSetReviewReadStatus();
  const isSetReviewsStatusAllowed = useSetReviewsStatusPermission();
  const isSetReviewsRespondedStatusAllowed = useSetReviewsRespondedStatusPermission();
  const isTranslateAllowed = useTranslatePermission();

  const { renderLeaveDialog, canContinue } = useLeaveDialog({
    showDialog: isResponseFormDirty,
  });

  if (!review) {
    return null;
  }

  const goBack = async () => {
    if (await canContinue()) {
      navigate(location.pathname.slice(0, location.pathname.lastIndexOf('/') + 1));
    }
  };

  const markAsUnread = () => {
    if (!review) return;
    setReviewStatus.mutate(
      {
        status: 'unread',
        reviews: [review.id],
      },
      {
        onSuccess: () => {
          updateReviewStatusById(queryClient, review.id, 'unread');
          goBack();
        },
      }
    );
  };

  const handleMarkAsResponded = () => {
    if (!review.id) return;

    saveResponse.mutate(
      {
        reviewId,
        payload: {
          // TODO: SIN-390 remove this line to stop sending the author to the backend
          author: accessTokenPayload.name,
          text: MARKED_AS_RESPONDED_WITH_AN_EMPTY_TEXT,
        },
      },
      {
        onSuccess: () => {
          snackbar.success(
            intl.formatMessage({
              id: 'inbox.review-marked-responded',
              defaultMessage: 'Review marked responded',
            })
          );
          updateHasExistingResponse(true);
          updateReviewStatusById(queryClient, review.review.id, 'responded');
        },
      }
    );
  };

  const navigateTo = async (position: 'previous' | 'next') => {
    const destination = { previous: prevReviewId, next: nextReviewId };
    const destinationReviewId = destination[position] ?? '';
    if (await canContinue()) navigate(location.pathname.replace(reviewId, destinationReviewId));
  };

  const handleTranslate = () => {
    const hasStoredTranslation = Boolean(review.review.translations?.[locale]);
    if (!hasStoredTranslation) {
      setShouldFetchTranslation(!shouldFetchTranslation);
    }
    setShouldDisplayTranslation(!shouldDisplayTranslation);
  };

  const getTranslateTooltip = () => {
    if (!isTranslateAllowed) {
      return (
        <FormattedMessage
          id="inbox.user.roles.read.only.inbox.alert"
          defaultMessage="Your role is read only"
        />
      );
    } else if (shouldDisplayTranslation) {
      return (
        <FormattedMessage id="inbox.review.action.show-original" defaultMessage="Show original" />
      );
    }
    return <FormattedMessage id="inbox.review.action.translate" defaultMessage="Translate" />;
  };

  const isOnlineReview = !review.survey;
  const shouldShowTrashIconButton = isOnlineReview;
  const shouldShowFlagIconButton = !isOnlineReview;

  const isDeletionProcessStartedOrFinished =
    isDeletionPendingOrRequested(review.deletion) || isDeletionConfirmed(review.deletion);
  const isModerationProcessStarted = ['requested', 'propagating'].includes(
    review.moderation?.status ?? ''
  );
  const isModerationProcessApproved = ['approved'].includes(review.moderation?.status ?? '');

  const isMarkAsUnreadDisabled =
    isLoading ||
    !isSetReviewsStatusAllowed ||
    isDeletionProcessStartedOrFinished ||
    isModerationProcessStarted ||
    isModerationProcessApproved;

  const isMarkAsRespondedDisabled =
    !!review.response ||
    !review.review?.respondable ||
    !isSetReviewsRespondedStatusAllowed ||
    isDeletionProcessStartedOrFinished ||
    isModerationProcessStarted ||
    isModerationProcessApproved;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'start' : 'center',
        justifyContent: 'space-between',
        padding: 1,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        ...(isMobile && {
          paddingBlock: 0,
        }),
      }}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          ...(isMobile && {
            order: 3,
            height: 44,
            alignItems: 'center',
          }),
        }}
      >
        <IconButtonWithTooltip
          onClick={goBack}
          icon={faArrowLeft}
          tooltip={<FormattedMessage id="inbox.back-to-inbox" defaultMessage="Back to Inbox" />}
          color="primary"
        />
        <Box component="span" sx={{ width: (theme) => theme.spacing(1) }} />
        {!isLoading && ['read', 'unread'].includes(review.meta.status ?? '') && (
          <IconButtonWithTooltip
            data-gtm-class="review_action_on_review"
            data-gtm-id="mark_as_unread"
            onClick={markAsUnread}
            icon={faEnvelope}
            tooltip={
              isSetReviewsStatusAllowed ? (
                <FormattedMessage id="inbox.mark-as-unread" defaultMessage="Mark as unread" />
              ) : (
                <FormattedMessage
                  id="inbox.user.roles.read.only.inbox.alert"
                  defaultMessage="Your role is read only"
                />
              )
            }
            disabled={isMarkAsUnreadDisabled}
            color="primary"
          />
        )}
        {!isLoading && review && (
          <>
            <IconButtonWithTooltip
              data-gtm-class="review_action_on_review"
              data-gtm-id="mark_as_responded"
              disabled={isMarkAsRespondedDisabled}
              onClick={handleMarkAsResponded}
              icon={faCheck}
              tooltip={
                isSetReviewsRespondedStatusAllowed ? (
                  <FormattedMessage
                    id="inbox.action.mark-as-responded"
                    defaultMessage="Mark as responded"
                  />
                ) : (
                  <FormattedMessage
                    id="inbox.user.roles.read.only.inbox.alert"
                    defaultMessage="Your role is read only"
                  />
                )
              }
              color="primary"
            />
            <IconButtonWithTooltip
              disabled={isLoading || !isTranslateAllowed}
              data-gtm-class="review_action_on_review"
              data-gtm-id="translate"
              data-gtm-param-1={locale}
              onClick={handleTranslate}
              icon={faLanguage}
              tooltip={getTranslateTooltip()}
              color="primary"
            />
            {shouldShowTrashIconButton && <ReviewDeletion />}
            {shouldShowFlagIconButton && <SurveyModeration />}
          </>
        )}
      </Stack>
      {isMobile && <Divider flexItem sx={{ order: 2, marginInline: -1 }} />}
      <Stack
        direction="row"
        gap={3}
        sx={{
          ...(isMobile && {
            order: 1,
            height: 44,
            width: '100%',
            justifyContent: 'space-between',
          }),
        }}
      >
        <Button
          startIcon={<StyledFontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 'inherit' }} />}
          disabled={!prevReviewId}
          onClick={() => navigateTo('previous')}
          data-gtm-class="inbox_navigation_button"
          data-gtm-id="previous"
        >
          <FormattedMessage id="inbox.review.navigation.previous" defaultMessage="Previous" />
        </Button>
        <Button
          endIcon={<StyledFontAwesomeIcon icon={faChevronRight} style={{ fontSize: 'inherit' }} />}
          disabled={!nextReviewId}
          onClick={() => navigateTo('next')}
          data-gtm-class="inbox_navigation_button"
          data-gtm-id="next"
        >
          <FormattedMessage id="inbox.review.navigation.next" defaultMessage="Next" />
        </Button>
      </Stack>
      {renderLeaveDialog()}
    </Box>
  );
}
