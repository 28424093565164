import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useQueryClient } from '@tanstack/react-query';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  type AccessGroup,
  DEFAULT_PAGE_SIZE,
  FETCH_INFINITE_ACCESS_GROUPS,
  searchPlaceholders,
  useInfiniteAccessGroups,
  useScopeStore,
} from '@trustyou/shared';
import {
  Alert,
  Box,
  Button,
  Dialog,
  StyledFontAwesomeIcon as Icon,
  ListItemText,
  Selector,
  Stack,
  Typography,
} from '@trustyou/ui';
import { groupBy } from 'lodash';

import { CreateUpdateAccessGroup } from '../../AccessGroups';
import styles from './styles';

type AccessGroupSelectionProps = {
  validated?: boolean;
};

export const AccessGroupSelection = ({ validated }: AccessGroupSelectionProps) => {
  const { selectedAccessGroup, setSelectedAccessGroup } = useScopeStore();
  const [showAddAccessGroup, setShowAddAccessGroup] = useState(false);

  const intl = useIntl();
  const queryClient = useQueryClient();

  const [searchKey, setSearchKey] = useState('');
  const { data, fetchNextPage, hasNextPage, isPending, isFetchingNextPage } =
    useInfiniteAccessGroups(DEFAULT_PAGE_SIZE, searchKey);
  const [items, setItems] = useState<AccessGroup[]>([]);

  const isLoading = isPending || isFetchingNextPage;

  useEffect(() => {
    setItems(data?.pages.map((page) => page.data).flat() || []);
  }, [data]);

  const onFetchNextPage = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const onSearch = (value: string) => {
    setSearchKey(value);
    queryClient.removeQueries({ queryKey: [FETCH_INFINITE_ACCESS_GROUPS] });
  };

  const onSelectItems = (values: AccessGroup[]) => {
    setSelectedAccessGroup(values.length ? values[0] : undefined);
  };

  const renderRowContent = (value: AccessGroup) => {
    let secondaryText = '';
    if (value.groups.length) {
      secondaryText = Object.entries(groupBy(value.groups, 'segment.name'))
        .map(([key, values]) => `${key}: ${values.map((group) => group.name)}`)
        .join('; ');
    } else if (value.entities.length) {
      secondaryText = intl.formatMessage(
        {
          id: 'organization.users.invite-user.select-access-group.entity-count',
          defaultMessage: '{count} entities',
        },
        { count: value.entities.length }
      );
    }
    return <ListItemText primary={value.name} secondary={secondaryText} />;
  };

  const toggleShowModal = () => setShowAddAccessGroup((state) => !state);

  return (
    <>
      <Stack gap={2}>
        <Box sx={styles.headerContainer}>
          <Typography variant="body1">
            <FormattedMessage
              id="organization.users.invite-user.select-access-group.title"
              defaultMessage="Select an access group"
            />
          </Typography>
          <Button variant="text" onClick={toggleShowModal}>
            <Icon sx={styles.addButtonIcon} icon={faPlus} />
            <FormattedMessage
              id="access-group.add-access-group"
              defaultMessage="Add access group"
            />
          </Button>
        </Box>
        <Box>
          <Selector
            searchPlaceholder={intl.formatMessage(searchPlaceholders.searchAccessGroups)}
            isLoading={isLoading}
            items={items}
            mode="single"
            onSelectItems={onSelectItems}
            onSearch={onSearch}
            renderRowContent={renderRowContent}
            selectedItems={selectedAccessGroup ? [selectedAccessGroup] : []}
            selectedHeaderTitle={
              <FormattedMessage
                id="organization.users.invite-user.select-access-group.selected-title"
                defaultMessage="Selected access group"
              />
            }
            onFetchNextPage={onFetchNextPage}
            isListDisabled={false}
            hideSelectResults
          />
        </Box>
        {validated && !selectedAccessGroup && (
          <Alert severity="error">
            <FormattedMessage
              id="organization.users.invite-user.select-access-group.error"
              defaultMessage="Select an access group"
            />
          </Alert>
        )}
      </Stack>
      <Dialog open={showAddAccessGroup} sx={styles.addModal} onClose={toggleShowModal}>
        <CreateUpdateAccessGroup onLeave={toggleShowModal} />
      </Dialog>
    </>
  );
};
