import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { type Entity, useEntity, useLanguageStore, useResponsive } from '@trustyou/shared';
import { Box, Divider, Stack, Typography } from '@trustyou/ui';

import { HighlightText } from './HighlightText';
import { ReviewMetadata } from './review-metadata';

import { useReview } from '../../../hooks';
import type { ReviewRoot } from '../../../types';
import { formatNewSurveyGuestInfo } from '../../../utils/guest-information';
import { hasMatches } from '../../../utils/sema';
import { GuestInformationDialog } from '../../guest-information-dialog';
import { ScoreChip } from '../../score-chip';
import { SemanticAnalysis } from '../../semantic-analysis';
import { Survey } from '../../survey';

export type ReviewItemProps = {
  shouldDisplayTranslation?: boolean;
};

export const ReviewSide = ({ shouldDisplayTranslation = false }: ReviewItemProps) => {
  const { isDesktop } = useResponsive();
  const { locale } = useLanguageStore();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => setIsOpen(!isOpen);
  const { reviewId = '' } = useParams();
  const reviewQuery = useReview({ reviewId });
  const { review, survey, sema_matches, meta } = reviewQuery.data ?? {};
  const entityQuery = useEntity(meta?.entity_id ?? '');

  const translatedTitle = review?.translations?.[locale]?.title;
  const title = shouldDisplayTranslation && translatedTitle ? translatedTitle : review?.title;

  const translatedText = review?.translations?.[locale]?.text;
  const text = shouldDisplayTranslation && translatedText ? translatedText || '' : review?.text;

  const formatGuestInformation = () => {
    if (survey) {
      return formatNewSurveyGuestInfo(survey.contact_info);
    }
    // in this case its just a review so show the author name
    return [{ label: 'name', value: review?.author }];
  };

  return (
    <>
      <Box
        sx={{
          ...(isDesktop && {
            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          }),
        }}
      >
        <ReviewMetadata
          reviewRoot={reviewQuery.data as ReviewRoot}
          entity={entityQuery.data as Entity}
          onPressGuestIcon={toggle}
        />
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack spacing={2} sx={{ padding: 2 }}>
            <Stack direction="row">
              <Box width="40px">
                <ScoreChip value={review?.score ?? 0} isRound />
              </Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: 14,
                  marginLeft: 1,
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'pre-wrap',
                  ...(!title && { color: (theme) => theme.palette.text.secondary }),
                }}
              >
                {title || <FormattedMessage id="inbox.no-title" defaultMessage="No title" />}
              </Typography>
            </Stack>
            <HighlightText text={text} sema_matches={sema_matches} />
            {survey && survey?.answers?.length > 0 && <Survey />}
          </Stack>
          {sema_matches && hasMatches(sema_matches) && (
            <>
              <Divider />
              <Stack spacing={1} sx={{ padding: 2 }}>
                <Typography variant="subtitle2">
                  <FormattedMessage
                    id="inbox.review.semanticAnalysis.accordion.title"
                    defaultMessage="Semantic analysis"
                  />
                </Typography>
                <SemanticAnalysis />
              </Stack>
            </>
          )}
        </Stack>
      </Box>
      <GuestInformationDialog isOpen={isOpen} onClose={toggle} data={formatGuestInformation()} />
    </>
  );
};
